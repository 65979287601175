/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from 'react';
import { useFrame } from '@react-three/fiber'; // Corrected import
import { useGLTF, useAnimations, Bounds,PerspectiveCamera } from '@react-three/drei';


export function Model(props) {
  const group = useRef(); 
  const { nodes, materials, animations } = useGLTF('/qnft_fin.glb');
  const { actions } = useAnimations(animations, group);


  
  return (
    <group ref={group} {...props} dispose={null}>
    <group name="Scene">
      <group
        name="Empty_(Animation)"
        position={[-0.036, 2.782, 0]}
        rotation={[0, 0.611, 0]}
        scale={[0.46, 2.964, 1.904]}>
        <mesh
          name="astronavtek_(1)_(1)"
          castShadow
          receiveShadow
          geometry={nodes['astronavtek_(1)_(1)'].geometry}
          material={materials['astronavtek (1) (1)']}
          position={[0.399, 0.18, 0.055]}
          rotation={[1.576, 0.005, 1.565]}
          scale={[1.313, 2.174, 0.844]}
        />
        <group name="Card" position={[-0.033, -0.38, 0]} scale={[0.377, 0.543, 0.845]}>
          <mesh
            name="Cube001"
            castShadow
            receiveShadow
            geometry={nodes.Cube001.geometry}
            material={materials['Card material']}
          />
          <mesh
            name="Cube001_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube001_1.geometry}
            material={materials['Glow 1']}
          />
        </group>
        <mesh
          name="Cylinder002"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder002.geometry}
          material={materials['Glow ring material']}
          position={[-0.376, -0.032, 0]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[0.337, 0.04, 0.525]}
        />
        <mesh
          name="Cylinder003"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder003.geometry}
          material={materials['Element material ']}
          position={[-0.368, 0.786, 0]}
          rotation={[-0.021, -0.009, -1.571]}
          scale={[0.401, 0.047, 0.624]}
        />
        <mesh
          name="Cylinder004"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder004.geometry}
          material={materials['Element material ']}
          position={[-0.368, -0.844, 0]}
          rotation={[3.121, -0.009, -1.571]}
          scale={[0.401, 0.047, 0.624]}
        />
        <mesh
          name="Cylinder005"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder005.geometry}
          material={materials['Element material ']}
          position={[-0.379, -0.222, 0]}
          rotation={[3.121, -0.009, -1.571]}
          scale={[0.415, 0.049, 0.645]}
        />
        <mesh
          name="Cylinder006"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder006.geometry}
          material={materials['Element material ']}
          position={[-0.38, 0.162, 0]}
          rotation={[-0.021, -0.009, -1.571]}
          scale={[0.415, 0.049, 0.645]}
        />
        <mesh
          name="ql"
          castShadow
          receiveShadow
          geometry={nodes.ql.geometry}
          material={materials.ql}
          position={[-0.505, -0.029, 0]}
          rotation={[1.571, 0, -1.571]}
          scale={[1.05, 2.174, 0.675]}
        />
        <mesh
          name="Text001"
          castShadow
          receiveShadow
          geometry={nodes.Text001.geometry}
          material={materials['Text card material.002']}
          position={[0.334, -0.547, 0.016]}
          rotation={[1.571, 0, -1.571]}
          scale={[0.525, 2.174, 0.337]}
        />
      </group>
    </group>
  </group>
  )
}

useGLTF.preload('/qnft_fin.glb')